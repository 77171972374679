import { Link } from "react-router-dom"
import styled from "styled-components"

//GuardHeader
export const GuardHeaderWrapper = styled.header`
  background: #fff;
  padding: 15px 20px;
  display: flex;
  gap: 14px;
`

export const HeaderLogo = styled.img`
  width: 100px;
  height: 18px;
`

export const Divider = styled.div`
  width: 1.5px;
  background: #e0e0e0;
`

export const HeaderTitle = styled.div`
  font-weight: 700;
  font-size: 12px;
  color: #2a292e;
`
//GuardFooter
export const GuardFooterWrapper = styled.footer`
  width: 100%;
  height: 170px;
  background: #1c2439;
  display: flex;
  flex-direction: column;
  text-align: center;
`

export const FooterLogo = styled.img`
  width: 120px;
  height: 30px;
  margin: 0 auto;
  margin-top: 27px;
`

export const FooterText = styled.p`
  margin-top: 25px;
  color: #fff;
  font-size: 10px;
`
export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  * {
    letter-spacing: 0 !important;
  }
`
export const GuardPage = styled.main`
  flex-grow: 1;
  padding-bottom: 100px;
`

export const GuardSection = styled.section`
  padding: 0 20px;
`

export const GuardHeading = styled.h3`
  font-size: 20px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #2a292e;
`

// GuardTopBanner
export const TopBanner = styled.div`
  height: 113px;
  background: #18634d;
  padding: 0 20px;
  display: flex;
  align-items: center;
  gap: 20px;
`

export const CircleBackground = styled.div`
  width: 64px;
  min-width: 64px;
  height: 64px;
  background: #d9f4e9;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

export const ShieldIcon = styled.img`
  width: 32px;
  height: 37px;
`

export const TopBannerContainer = styled.div`
  width: 100%;
  max-width: 236px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const TopText = styled.div`
  width: 100%;
  height: 23px;
  background: #3dc289;
  border-radius: 20px;
  display: flex;
  align-items: center;
`

export const BannerText1 = styled.p`
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  padding: 3px 12px;
  margin: 0;
`

export const BannerText2 = styled.p`
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
`

export const HighlightedText = styled.span`
  color: #ffdf5a;
  font-size: 14px;
  font-weight: 700;
`

// GuardRegistSucces
export const ContentBox = styled.section`
  padding: 20px;
  background: #d9f4e9;
  border-radius: 10px;

  > p {
    font-size: 12px;
    color: #272e40;
  }
`

export const SuccessMessage = styled.p`
  text-align: center;
  font-size: 14px !important;
  font-weight: 500;
  padding: 24px 0;
`

// GuardFail
export const InfoBox = styled.section`
  background: #f7f7f7;
  padding: 20px;
  border-radius: 10px;

  p {
    color: #2a292e;
  }
`

export const ErrorIconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const ErrorIcon = styled.img`
  width: 20px;
  height: 20px;
`

export const ErrorText = styled.p`
  font-size: 14px;
  font-weight: 700;
`

export const ErrorMessage = styled.p`
  font-size: 14px;
  font-weight: 500;
  margin: 20px 0;
`

export const ProcedureText = styled.p`
  font-size: 12px;
`
// GuardRegistForm
export const GuardDescriptionText = styled.p`
  margin: 16px 0 30px 0;
  font-size: 10px;
  color: #757578;

  span {
    color: #e7321a;
  }
`
export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`
export const InfoHeading = styled.h5<{ fontSize?: number }>`
  font-size: ${(props) => props.fontSize || 16}px;
  font-weight: 700;
  color: #2a292e;
  margin-bottom: 10px;
`
export const InfoDescription = styled.p`
  color: #757578 !important;
  font-size: 12px;
`

export const GuardCertInput = styled.input`
  padding: 18px 16px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  color: #2a292e;
  border-radius: 16px;
  font-size: 14px;
  margin-bottom: 20px;
  width: 100%;
  &::placeholder {
    color: #afaeb2;
  }
  &:focus {
    outline: 1.2px solid #272e40;
  }
`
export const GuardNumber = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 10px;

  p {
    width: 174px;
    font-size: 24px;
    font-weight: 700;
  }
`

export const CallButton = styled.button`
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid #f1f1f1;
  width: 34px;
  height: 34px;
  padding: 8px;
`

export const CallImg = styled.img`
  width: 18px;
  height: 18px;
`
export const ConfirmButton = styled.button`
  background-color: #3dc289;
  width: 100%;
  padding: 14px 20px;
  border-radius: 100px;
  box-shadow: 0px 4px 4px 0px rgba(61, 194, 137, 0.16);
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
`
export const TermsAgreementWrapper = styled.label`
  display: flex;
  align-items: center;
  margin: 21px 0 32px;

  input[type="checkbox"] {
    display: none;
  }

  p {
    margin-left: 14px;
    font-size: 12px;
    color: #2a292e;
  }
`
export const TermsLink = styled(Link)`
  margin-left: auto;
  color: #3dc289;
  font-size: 12px;
  text-decoration: underline;
`

//GuardTerm
export const TermsSection = styled.section`
  padding-top: 10px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`
export const TermTitle = styled.p`
  color: #272e40;
`

export const TermDescription = styled.p`
  color: #272e40;
  white-space: pre-line;
`
