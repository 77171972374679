import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import { checkDeviceWidth, kakaoInit } from "service/utils"

import GuardLayout from "layouts/GuardLayout"
import MainLayout from "layouts/MainLayout"
import ScrollTop from "layouts/ScrollTop"
import { useEffect } from "react"
import { useSetRecoilState } from "recoil"
import { isPcState } from "store"
import Cancel from "views/Cancel"
import Customer from "views/Customer"
import Faq from "views/Customer/Faq"
import Inquiry from "views/Customer/Inquiry"
import News from "views/Customer/News"
import Notice from "views/Customer/Notice"
import NoticeDetail from "views/Customer/Notice/NoticeDetail"
import Refund from "views/Customer/Refund"
import Guard from "views/Guard"
import GuardTerm from "views/Guard/Terms"
import Join from "views/Join"
import Main from "views/Main"
import Phishing from "views/Phishing"
import NewPhishingDetail from "views/Phishing/NewPhishingDetail"
import PhishingList from "views/Phishing/PhishingList"
import Respond from "views/Respond"
import Guide from "views/Respond/Guide"
import Share from "views/Respond/Share"
import Terms from "views/Terms"
import Personal from "views/Terms/Personal"
import Used from "views/Terms/Used"

const App = () => {
  const setIsPc = useSetRecoilState(isPcState)
  useEffect(() => {
    kakaoInit()
    window.addEventListener("resize", () => {
      const checkPc = checkDeviceWidth(window.innerWidth)
      setIsPc(checkPc)
    })
  }, [setIsPc])
  return (
    <div className="app">
      <BrowserRouter>
        <ScrollTop />
        <Routes>
          <Route element={<MainLayout />}>
            <Route path="/" element={<Main />} />
            <Route path="/join" element={<Join />} />
            <Route path="/customer" element={<Customer />}>
              <Route path="notice">
                <Route index element={<Notice />} />
                <Route path=":noticeId" element={<NoticeDetail />} />
              </Route>
              <Route path="news" element={<News />} />
              <Route path="faq" element={<Faq />} />
              <Route path="refund" element={<Refund />} />
              <Route path="inquiry" element={<Inquiry />} />
              <Route path="*" element={<Navigate replace to="notice" />} />
            </Route>
            <Route path="/respond" element={<Respond />}>
              <Route path="guide" element={<Guide />} />
              <Route path="share" element={<Share />} />
              <Route path="*" element={<Navigate replace to="guide" />} />
            </Route>
            <Route path="/cancel" element={<Cancel />} />
            <Route path="/cancel/:telecomCode" element={<Cancel />} />
            <Route path="/phishing" element={<Phishing />}>
              <Route index element={<PhishingList />} />
              <Route path="main/:mainId" element={<PhishingList />} />
              <Route path="new/:newId" element={<NewPhishingDetail />} />
            </Route>
            <Route path="/terms" element={<Terms />}>
              <Route path="used" element={<Used />} />
              <Route path="personal" element={<Personal />} />
              <Route path="*" element={<Navigate replace to="used" />} />
            </Route>
            <Route path="/LGUP/unsubscribe" element={<Navigate replace to="/cancel/2" />} />
            <Route path="/service/cancel" element={<Navigate replace to="/cancel" />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Route>
          <Route element={<GuardLayout />}>
            <Route path="guard/:transId" element={<Guard />} />
            <Route path="guard/terms" element={<GuardTerm />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
