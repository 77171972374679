import { CustomerElementType, NewsDataType, PhishingElementType } from "types"
import { decodeData, hostServer, httpData } from "service/utils"

import { IMAGE_SERV } from "service/constant"
import axios from "axios"

export const fetchDefaultData = <T>(CmdType: string, Body?: T) => {
  return axios
    .post(hostServer(), httpData<T>(CmdType, Body))
    .then((response) => {
      const { Header, Body } = JSON.parse(response.data)
      return { Header, Body }
    })
    .catch((error) => {
      return error
    })
}
export const fetchMcrsDefaultData = <T>(CmdType: string, Body?: T) => {
  return axios
    .post(hostServer("MCRS"), httpData<T>(CmdType, Body))
    .then((response) => {
      const { Header, Body } = response.data
      return { Header, Body }
    })
    .catch((error) => {
      return error
    })
}
export const fetchPhishingData = <T>(CmdType: string, Body: T) => {
  return axios
    .post(hostServer(), httpData<T>(CmdType, Body))
    .then((response) => {
      const { Header, Body } = JSON.parse(response.data)
      return {
        Header,
        Body: Body.map((element: PhishingElementType) => {
          return {
            ...element,
            CardThumbNail: IMAGE_SERV + element.CardThumbNail,
            CardThumbNailAlt: decodeData(element.CardThumbNailAlt),
            CardTitle: decodeData(element.CardTitle),
            Contents: decodeData(element.Contents),
            ContentsText: decodeData(element.ContentsText),
            Title: decodeData(element.Title),
            altImages: element.altImages.map((altImgPath: string) => decodeData(altImgPath)),
            images: element.images.map((imgPath: string) => IMAGE_SERV + imgPath),
            serviceImg: element.serviceImg ? IMAGE_SERV + "serviceimage/" + element.serviceImg : element.serviceImg,
          }
        }),
      }
    })
    .catch((error) => {
      return error
    })
}

export const fetchPhishingDetailData = <T>(CmdType: string, Body: T) => {
  return axios
    .post(hostServer(), httpData<T>(CmdType, Body))
    .then((response) => {
      const { Body } = JSON.parse(response.data)
      return {
        ...Body,
        CardThumbNail: IMAGE_SERV + Body.CardThumbNail,
        CardThumbNailAlt: decodeData(Body.CardThumbNailAlt),
        CardTitle: decodeData(Body.CardTitle),
        Contents: decodeData(Body.Contents),
        ContentsText: decodeData(Body.ContentsText),
        Title: decodeData(Body.Title),
        images: Body.images.map((imgPath: string) => IMAGE_SERV + imgPath),
        serviceImg: Body.serviceImg ? IMAGE_SERV + "serviceimage/" + Body.serviceImg : Body.serviceImg,
      }
    })
    .catch((error) => {
      return error
    })
}

export const fetchCustomerData = <T>(CmdType: string, Body: T) => {
  return axios
    .post(hostServer(), httpData<T>(CmdType, Body))
    .then((response) => {
      const { Header, Body } = JSON.parse(response.data)
      return {
        Header,
        Body: Body.map((element: CustomerElementType) => {
          return {
            ...element,
            Title: decodeData(element.Title),
            Contents: decodeData(element.Contents),
          }
        }),
      }
    })
    .catch((error) => {
      return error
    })
}

export const fetchNewsData = <T>(CmdType: string, Body: T) => {
  return axios
    .post(hostServer(), httpData<T>(CmdType, Body))
    .then((response) => {
      const { Header, Body } = JSON.parse(response.data)
      return {
        Header,
        Body: Body.map((element: NewsDataType) => {
          return {
            ...element,
            NewsTitle: decodeData(element.NewsTitle),
          }
        }),
      }
    })
    .catch((error) => {
      return error
    })
}

export const fetchNoticeDetailData = <T>(CmdType: string, Body: T) => {
  return axios
    .post(hostServer(), httpData<T>(CmdType, Body))
    .then((response) => {
      const { Header, Body } = JSON.parse(response.data)
      return {
        Header,
        Body: {
          ...Body,
          Title: decodeData(Body.Title),
          Contents: decodeData(Body.Contents),
        },
      }
    })
    .catch((error) => {
      return error
    })
}

export const fetchFaqListData = <T>(CmdType: string, Body: T) => {
  return axios
    .post(hostServer(), httpData<T>(CmdType, Body))
    .then((response) => {
      const { Header, Body } = JSON.parse(response.data)
      return {
        Header,
        Body: {
          ...Body,
          LIST: Body.LIST.map((element: CustomerElementType) => {
            return {
              ...element,
              Title: decodeData(element.Title),
              Contents: decodeData(element.Contents),
            }
          }),
        },
      }
    })
    .catch((error) => {
      return error
    })
}
