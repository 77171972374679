import {
  GuardHeading,
  GuardPage,
  GuardSection,
  TermDescription,
  TermTitle,
  TermsSection,
} from "components/Guard/styles"

import { GuardTopBanner } from "components/Guard"

const TERMS_GUARD = [
  {
    title: "1. 수집정보",
    desc: `㈜데이터유니버스는 ‘스마트피싱보호’ 서비스의 원활한 이용을 위하여 아래와 같은 정보를 수집합니다.
    * 수집 정보 : 휴대폰번호, 이통사정보`,
  },
  {
    title: "2. 수집 및 이용 목적",
    desc: "수집된 정보는 스마트피싱보호 서비스의 가족보호알림 수신을 위한 목적으로 활용합니다.",
  },
  {
    title: "3. 보관 및 이용 기간",
    desc: `이용자의 정보는 가족보호알림 신청자가 수신자정보를 삭제 시 즉시 파기합니다. 단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우 아래와 같이 관계법령에서 정한 일정 기간동안 개인정보를 보관할 수 있습니다.
  - 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 (전자상거래등에서의 소비자보호에 관한 법률)`,
  },
]

const GuardTerm = () => {
  return (
    <GuardPage>
      <GuardTopBanner />
      <GuardSection>
        <GuardHeading>개인정보 수집 및 이용안내</GuardHeading>
        <TermsSection>
          {TERMS_GUARD.map((term, index) => (
            <div key={index}>
              <TermTitle>{term.title}</TermTitle>
              <TermDescription>{term.desc}</TermDescription>
            </div>
          ))}
        </TermsSection>
      </GuardSection>
    </GuardPage>
  )
}

export default GuardTerm
