import { GuardFooter, GuardHeader } from "components/Guard"

import { PageContainer } from "components/Guard/styles"
import { Outlet } from "react-router-dom"

const GuardLayout = () => {
  return (
    <PageContainer>
      <GuardHeader />
      <Outlet />
      <GuardFooter />
    </PageContainer>
  )
}

export default GuardLayout
