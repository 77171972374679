import { GuardFail, GuardRegistForm, GuardTopBanner } from "components/Guard"
import { GuardHeading, GuardPage, GuardSection } from "components/Guard/styles"

import { LoadingPop } from "components/Popup"
import { useGuardData } from "hooks/query"
import { useParams } from "react-router-dom"
import { layerOpen } from "service/utils"

const Guard = () => {
  const { transId } = useParams() as { transId: string }
  const { data, isLoading } = useGuardData(transId)
  const guardData = data?.Body
  return (
    <GuardPage>
      <GuardTopBanner />
      {isLoading &&
        (function () {
          layerOpen()
          return <LoadingPop />
        })()}
      <GuardSection>
        <GuardHeading>가족피싱알림</GuardHeading>
        {!isLoading && (guardData ? <GuardRegistForm transId={transId} guardData={guardData} /> : <GuardFail />)}
      </GuardSection>
    </GuardPage>
  )
}

export default Guard
