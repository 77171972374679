import { FormEvent, KeyboardEvent, useEffect, useRef, useState } from "react"
import {
  BannerText1,
  BannerText2,
  CallButton,
  CircleBackground,
  ConfirmButton,
  ContentBox,
  Divider,
  ErrorIcon,
  ErrorIconWrapper,
  ErrorMessage,
  ErrorText,
  FooterLogo,
  FooterText,
  GuardCertInput,
  GuardDescriptionText,
  GuardFooterWrapper,
  GuardHeaderWrapper,
  GuardNumber,
  HeaderLogo,
  HeaderTitle,
  HighlightedText,
  InfoBox,
  InfoDescription,
  InfoHeading,
  InfoWrapper,
  ProcedureText,
  ShieldIcon,
  SuccessMessage,
  TermsAgreementWrapper,
  TermsLink,
  TopBanner,
  TopBannerContainer,
  TopText,
} from "./styles"

import FooterLogoSrc from "assets/images/footer_logo.png"
import HeaderLogoSrc from "assets/images/header_logo.png"
import CallIconSrc from "assets/images/icon_call_green.png"
import errorIconSrc from "assets/images/icon_error.png"
import shieldIconSrc from "assets/images/icon_shield_2.png"
import { ReactComponent as CheckBox } from "assets/svgs/checkbox.svg"
import { useGuardVerifyQueryMutate } from "hooks/query"
import { CMDTYPE_SEND_GUARD_VERIFY } from "service/constant"
import { onScrollTop } from "service/utils"
import { CallGuardResponseType } from "types"

export const GuardHeader = () => {
  return (
    <GuardHeaderWrapper>
      <HeaderLogo src={HeaderLogoSrc} alt="스마트피싱보호" />
      <Divider />
      <HeaderTitle>보이스피싱 예방 서비스</HeaderTitle>
    </GuardHeaderWrapper>
  )
}

export const GuardFooter = () => {
  return (
    <GuardFooterWrapper>
      <FooterLogo src={FooterLogoSrc} alt="DataUniverse" />
      <FooterText>
        (주)데이터유니버스 <br />
        대표 : 강원석 | 서울특별시 영등포구 국제금융로8길 32 여의도파이낸스 타워 <br />
        고객지원센터 : 1811-3751 (공휴일 휴무 09:00 ~ 12:00 / 13:00 ~ 18:00) <br />
        Copyright © 2018 DataUniverse. All Rights Reserved.
      </FooterText>
    </GuardFooterWrapper>
  )
}

export const GuardTopBanner = () => {
  return (
    <section>
      <TopBanner>
        <CircleBackground>
          <ShieldIcon src={shieldIconSrc} alt="Check Icon" />
        </CircleBackground>
        <TopBannerContainer>
          <TopText>
            <BannerText1>STOP.THINK.CONNECT</BannerText1>
          </TopText>
          <BannerText2>
            당신의 소중한 가족을 <br />
            <HighlightedText>보이스피싱 피해</HighlightedText>로부터 보호해주세요.
          </BannerText2>
        </TopBannerContainer>
      </TopBanner>
    </section>
  )
}

export const GuardRegistSuccess = () => {
  return (
    <ContentBox>
      <SuccessMessage>가족피싱알림 서비스가 등록되었습니다.</SuccessMessage>
    </ContentBox>
  )
}

export const GuardFail = () => {
  return (
    <InfoBox>
      <ErrorIconWrapper>
        <ErrorIcon src={errorIconSrc} />
        <ErrorText>등록불가</ErrorText>
      </ErrorIconWrapper>
      <ErrorMessage>
        이미 등록되었거나, 인증번호 3회 오류로 등록이 불가합니다. <br />
        다시 기능을 요청 해주세요.
      </ErrorMessage>
      <ProcedureText>
        [기능 요청 절차] <br />
        ① 스마트피싱보호 앱 실행 <br />
        ② 스마트설정 메뉴 이동
        <br />
        ③ 내가 보호받기 선택 후 ‘등록하기’ <br />
      </ProcedureText>
    </InfoBox>
  )
}

interface GuardRegistFormProps {
  transId: string
  guardData: CallGuardResponseType
}
export const GuardRegistForm = ({ transId, guardData }: GuardRegistFormProps) => {
  const certInput = useRef<HTMLInputElement>(null)
  const { mutate: guardVerify } = useGuardVerifyQueryMutate()
  const [stage, setStage] = useState<number>(0)
  const [certNumber, setCertNumber] = useState<string>(sessionStorage.getItem("certNumber") || "")
  const [termsCheck, setTermsCheck] = useState<boolean>(sessionStorage.getItem("termsCheck") === "true")

  useEffect(() => {
    if (certInput.current != null) {
      certInput.current.focus()
    }
  }, [])

  useEffect(() => {
    onScrollTop(false)
  }, [stage])

  const handleClickCall = () => (window.location.href = `tel:${guardData.SenderPNumber}`)

  const handleChangeCertNumber = (event: FormEvent<HTMLInputElement>) => {
    const regexp: RegExp = /[^0-9]/g
    const value = event.currentTarget.value.replace(regexp, "").slice(0, 4)
    setCertNumber(value)
    sessionStorage.setItem("certNumber", value)
  }

  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      verifySubmit()
    }
  }

  const handleTermsAgreement = () => {
    const isAgreed = window.confirm("알림 등록을 위해서 개인정보 수집 및 이용에 동의하셔야 합니다.\n동의하시겠습니까?")
    if (isAgreed) {
      setTermsCheck(true)
      sessionStorage.setItem("termsCheck", "true")
    }
    return isAgreed
  }

  const verifySubmit = () => {
    if (certNumber.length !== 4) {
      alert("인증번호를 입력해주세요.")
      return false
    }
    if (!termsCheck && !handleTermsAgreement()) {
      return
    }

    guardVerify(
      {
        CmdType: CMDTYPE_SEND_GUARD_VERIFY,
        Body: {
          TransID: transId,
          CertNumber: certNumber,
        },
      },
      {
        onSuccess: (data) => {
          setCertNumber("")
          sessionStorage.removeItem("certNumber")

          if (data.Header.ErrCode !== 0) {
            alert(data.Header.ErrMsg)
            switch (data.Header.ErrCode) {
              case 100501: //인증번호 오류
                break
              case 100502: //시간 초과
              case 100503: //횟수 초과
              case 100504: //요청정보 없음
                setStage(2)
            }
          } else {
            setStage(1)
          }
        },
      },
    )
  }
  const handleChangeTerms = () => {
    const updatedTermsCheck = !termsCheck
    setTermsCheck(updatedTermsCheck)
    sessionStorage.setItem("termsCheck", JSON.stringify(updatedTermsCheck))
  }

  if (stage === 1) {
    return <GuardRegistSuccess />
  }

  if (stage === 2) {
    return <GuardFail />
  }

  return (
    <>
      <ContentBox>
        <p>
          보호받는 가족이 피싱 의심 전화를 수신하는 경우, 고객님에게 알림을 보냅니다. <br />
          *통화 시간: 2, 5, 7분(총 3번) <br />
          *단말 기종에 따라 알림 시간 오차 발생
        </p>
      </ContentBox>
      <GuardDescriptionText>
        ※ 본 기능은 고객님께서 스마트피싱보호 가입자가 아니더라도,
        <span> 무료</span>로 SMS를 받을 수 있습니다.
      </GuardDescriptionText>
      <InfoWrapper>
        <InfoBox>
          <InfoHeading>보호받는 번호</InfoHeading>
          <InfoDescription>가족이 요청한 내용이 맞는지 전화로 확인해보세요.</InfoDescription>
          <GuardNumber>
            <p>
              {guardData.SenderPNumber.slice(0, 3)}-{guardData.SenderPNumber.slice(3, 7)}-
              {guardData.SenderPNumber.slice(7, 11)}
            </p>
            <CallButton onClick={handleClickCall}>
              <img src={CallIconSrc} alt="phone call" />
            </CallButton>
          </GuardNumber>
        </InfoBox>
        <InfoBox>
          <InfoHeading fontSize={12}>인증번호</InfoHeading>
          <GuardCertInput
            ref={certInput}
            value={certNumber}
            placeholder="인증번호 입력 "
            onChange={handleChangeCertNumber}
            onKeyUp={handleKeyUp}
          />
          <InfoDescription>
            가족피싱알림 등록을 원하시면, 가족에게 전송된 인증번호를 입력 후 승인해주세요.
          </InfoDescription>
        </InfoBox>
      </InfoWrapper>
      <TermsAgreementWrapper>
        <input type="checkbox" checked={termsCheck} onChange={handleChangeTerms} />
        <CheckBox fill={termsCheck ? " #3DC289" : "#f1f1f1"} />
        <p>개인정보 수집 및 이용 동의</p>
        <TermsLink to="/guard/terms">약관보기</TermsLink>
      </TermsAgreementWrapper>
      <ConfirmButton onClick={verifySubmit}>확인</ConfirmButton>
    </>
  )
}
